/* ALERT MODAL "COOKIE POLICY" FOR EUROPARL */
/* Component "COOKIES ALERT" */
/* Copyright : European Parliament */

/* ========================================================================================================================================================================== */
/* == GENRIC STYLES ============================================================================================================================== */
/* ========================================================================================================================================================================== */

/*  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* STYLE GENERIQUES : Conteneurs principaux */

body[data-jsactive] {
  #cookie-policy{
    &.epjs_cookiepolicy {
      position: fixed;
      display: block;
      z-index: 5000;
      /*overflow: hidden;*/
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 5px 0 0 0;
      font-family: Helvetica, Arial, sans-serif;
      text-align: center;
      letter-spacing: normal;
      white-space: normal;
      color: inherit;

      &.epjs_displayed .cookie-consent-popup-container {
          transform: translate(0, 0);
          transition: transform 1s ease-out 0s;
          & > div {
            &:focus{
              outline: none;
            }
          }
      }

      .cookie-consent-popup-container {
        position: relative;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 20px 0 10px 0;
        transform: translate(0, 110%);
        transition: transform 0.5s ease-out 0s;
        background-color: rgba(51, 51, 51, 0.95);
        color: #fff;
        .epjs_tag-url{
          margin-top: -36px;
          margin-bottom: 20px;
          .tag-url{
            position: relative;
            display: inline-block;
            padding: 4px 20px;
            background-color: #fff;
            color: #000;
            font-size: .8rem;
            box-shadow: 0 4px 5px 1px rgb(0 0 0 / 20%);
          }
        }
        .epjs_title {
          position: static;
          display: block;
          margin: 0;
          padding: 0 10px 14px 10px;
          font-family: Georgia, Garamond, serif, Palatino;
          font-size: 1rem;
          font-weight: lighter;
          color: inherit;
        }
        .epjs_text {
          position: static;
          display: block;
          margin: 0;
          padding: 0 10px 14px 10px;
          font-weight: lighter;
          color: inherit;
          -webkit-font-smoothing: auto;
          a, p{
            font-size: $font-size !important;
            line-height: $line-height !important;
          }
          p{
            margin-top: 0;
            margin-bottom: 0;
            color: #fff;
          }
          a{
            &.cc-link-default{
              display: inline-block;
              &:hover {
                color: #32D3FB !important;
              }
              &:focus {
                color: #32D3FB !important;
                outline: 3px dotted #fff;
              }
            }
          }
        }
        .epjs_buttons {
          position: static;
          margin: 0;
          padding: 0;
          text-align: center;
          letter-spacing: -0.3em;
          color: inherit;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          a, a:link, a:visited, button{
            position: static;
            display: inline-block;
            width: auto;
            margin: 0 10px 10px 10px;
            padding: 0 10px;
            background: #fff;
            border: 1px solid #fff;
            outline: none;
            cursor: pointer;
            font-family: Helvetica, Arial, sans-serif;
            text-align: center;
            letter-spacing: -0.3em;
            white-space: nowrap;
            vertical-align: middle;
            color: #505154;
          }
          a:before, button:before, a span, button span{
            position: static;
            display: inline-block;
            width: auto;
            margin: 0;
            padding: 0;
            cursor: pointer;
            font-size: $font-size !important;
            line-height: $line-height !important;
            text-align: center;
            letter-spacing: normal;
            vertical-align: middle;
            color: inherit;
          }
          a:before, button:before{
            overflow: hidden;
            width: 0;
            height: 25px;
            text-indent: 10px;
            content: ' ';
          }
          a:hover, a:focus, a:active, button:hover, button:focus, button:active{
            background: #1093cf;
            border-color: #1093cf;
            color: #fff;
          }
          button{
            &.epjs_agree {
              background: #326aab;
              border-color: #326aab;
              color: #fff;
              &:hover, &:focus, &:active{
                background: #fff;
                border-color: #b1b3b5;
                color: #1093cf;
              }
            }
          }
        }
      }

      &:focus{
        outline: none;
      }
    }
  }
}
